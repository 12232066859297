<template>
  <v-dialog :value="dialog" width="780" persistent @keydown.esc="closeModal">
    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
    <v-card class="card-style">
      <v-toolbar flat>
        <v-toolbar-title class="title-header">
          {{ note.id ? $t('Note.edit') : $t('Note.new_note') }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon color="gray" @click="closeModal" right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="form-register">
        <v-form fast-fail ref="formRef">
          <v-row no-gutters class="title-description pt-5">
            <v-col class="text-start pl-1">{{ $t('title') }}* </v-col>
          </v-row>
          <v-row no-guttes class="mt-n2">
            <v-col>
              <v-text-field
                v-model="note.title"
                background-color="white"
                color="#39af49"
                hide-details="auto"
                solo
                dense
                class="inputs-border"
                counter="110"
                :label="$t('title')"
                :rules="[required]"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters class="title-description">
            <v-col class="text-start d-none d-sm-none d-md-flex">
              {{ $t('description') }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="note.description"
                background-color="white"
                color="#39af49"
                :placeholder="$t('description')"
                hide-details="auto"
                solo
                dense
                auto-grow
                rows="4"
                class="inputs-border"
                counter="420"
              >
              </v-textarea>
            </v-col>
          </v-row>

          <v-row no-gutters class="title-description">
            <v-col cols="12" md="6" class="text-start pl-1">
              {{ $t('Note.note_category') }}*
            </v-col>
          </v-row>
          <v-row no-guttes class="mt-n2">
            <v-col cols="12">
              <v-select
                v-model="note.category_id"
                class="inputs-border"
                item-text="text"
                item-value="id"
                background-color="white"
                color="#39af49"
                hide-details="auto"
                solo
                dense
                :items="getCategories"
                :label="$t('select')"
                :rules="[required]"
                @change="changeCategory"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row no-gutters class="title-description">
            <v-col cols="12" md="6" class="text-start pl-1">
              {{ $t('Note.note_plot') }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="note.plot_id"
                class="inputs-border"
                item-text="nome"
                item-value="id"
                background-color="white"
                color="#39af49"
                hide-details="auto"
                solo
                dense
                :items="plots"
                :label="$t('Note.select_plot')"
                @change="changePlot"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row no-gutters class="title-description">
            <v-col cols="12" class="text-start pl-1">
              {{ $t('upload_file') }}<br />
              <span class="info-file">
                {{ $t('files.type_files_allowed') }}<br />
                {{ $t('files.size_files_allowed') }}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .heic, .xlsx, .xls, .pdf, .doc, .docx"
                class="d-none"
                ref="fileInput"
                @change="inputChanged"
                multiple
              />
              <v-btn
                block
                :loading="isSelectingFile"
                @click="handleFileImport"
                class="btn-upload-file inputs-border text-none"
              >
                {{ $t('select_file') }}
                <v-icon class="icon-button" small color="#39af49">
                  mdi-folder
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-chip
                v-for="(file, index) in note.files"
                :key="index"
                small
                text-color="white"
                color="#39af49"
                close
                class="mx-2"
                @click:close="removeInputFile(index)"
              >
                {{ file.name }}
              </v-chip>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="distance-buttons">
        <v-btn class="text-none button-remove-and-close" @click="closeModal"
          ><span class="text-cancel-and-remove">{{ $t('cancelar') }}</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="white--text text-none button-register-confirm"
          :loading="saving"
          :disabled="disableRegisterButton"
          @click="register"
        >
          {{ note.id ? $t('editar') : $t('adicionar') }}
          <v-icon class="icon-button" small>mdi-plus-box</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { logEvent, events } from '@/services/analytics'
import { required } from '@/utils/formRules'

export default {
  name: 'ModalNote',

  data() {
    return {
      saving: false,
      note: {
        title: '',
        description: '',
        category_id: null,
        plot_id: null,
        files: [],
      },
      required,
      isSelectingFile: false,
    }
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    currentNote: {
      type: Object,
      default: null,
    },
  },

  watch: {
    dialog: {
      handler(isOpened) {
        if (!isOpened) {
          return
        }
        this.openModal()
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('notes', ['notesCategories']),
    ...mapGetters('plot', ['plots']),
    disableRegisterButton() {
      return !this.note.title && !this.note.category_id
    },
    isEditing() {
      return this.note.id ? true : false
    },
    getCategories() {
      let notesCategories = this.notesCategories

      return notesCategories.map((category) => {
        return {
          id: category.id,
          text: this.$t(`Note.category.${category.name}`),
        }
      })
    },
  },

  methods: {
    ...mapActions('notes', ['fetchNotes']),
    openModal() {
      this.note = {
        id: this.currentNote?.id,
        title: this.currentNote?.title || '',
        description: this.currentNote?.description || '',
        category_id: this.currentNote?.category.id || null,
        plot_id: this.currentNote?.plot?.id || null,
        files: this.currentNote?.files || [],
      }

      this.resetValidation(this.currentNote)
    },
    handleFileImport() {
      this.isSelectingFile = true

      window.addEventListener(
        'focus',
        () => {
          this.isSelectingFile = false
        },
        { once: true }
      )

      this.$refs.fileInput.click()
    },
    inputChanged(e) {
      const files = Array.from(e.target.files)
      this.note.files = this.note.files.concat(files)
    },
    removeInputFile(index) {
      this.note.files.splice(index, 1)
    },
    resetValidation(note) {
      setTimeout(() => {
        note
          ? this.$refs.formRef.validate()
          : this.$refs.formRef.resetValidation()
      }, 100)
    },
    closeModal() {
      this.$emit('close')
    },
    changeCategory(categoryId) {
      const category = this.notesCategories.find(
        (category) => category.id === categoryId
      )
      logEvent(events.notesModule.selectCategory(category.name))
    },
    changePlot() {
      logEvent(events.notesModule.associateWithPlot)
    },
    async register() {
      try {
        this.saving = true

        if (this.isEditing) {
          this.note._method = 'PUT'
        }

        const formData = this.createFormData(this.note)

        const action = this.isEditing
          ? this.$api.notes.update
          : this.$api.notes.create

        const response = await action(
          this.currentFarmId,
          formData,
          this.isEditing ? formData.get('id') : null
        )

        logEvent(
          this.isEditing
            ? events.notesModule.editedNote
            : events.notesModule.createdNote
        )

        const note = response.data

        this.note = {
          id: note.id,
          title: note.title,
          description: note.email,
          category_id: note.category_id,
          plot_id: note.plot?.id ?? null,
        }

        this.saving = false
        await this.fetchNotes({ farmId: this.currentFarmId })

        this.$root.$emit('notify', 'success', this.$t('Note.register_success'))

        this.closeModal()
      } catch (error) {
        this.saving = false

        throw error
      }
    },
    createFormData(obj) {
      const formData = new FormData()

      Object.keys(obj).forEach((key) => {
        const value = obj[key]

        if (key === 'files') {
          if (Array.isArray(value) && value.length > 0) {
            value.forEach((file, index) => {
              if (file instanceof File) {
                formData.append(`${key}[${index}]`, file)
              } else if (typeof file === 'object') {
                Object.keys(file).forEach((fileKey) => {
                  const fileValue = file[fileKey]

                  if (fileValue instanceof Blob) {
                    formData.append(`${key}[${index}]`, fileValue, file.name)
                  } else {
                    formData.append(`${key}[${index}][${fileKey}]`, fileValue)
                  }
                })
              }
            })
          }
        } else {
          if (value === null) {
            formData.append(key, '')
          } else {
            formData.append(key, value)
          }
        }
      })

      return formData
    },
  },
}
</script>

<style scoped>
::v-deep .v-chip.v-size--small {
  margin-bottom: 8px;
}
::v-deep .theme--light.v-select .v-select__selection--comma {
  color: #5b6459;
}
::v-deep .v-list-item__content {
  color: #5b6459;
}
::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: -10px;
}
::v-deep
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
}
::v-deep
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot {
  border-radius: 8px;
}

::v-deep .theme--light.v-input {
  color: #788476;
}

::v-deep .v-text-field.v-text-field--solo .v-input__control input {
  color: #5b6459;
}
::v-deep .title-description {
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #253521;
}
::v-deep.v-input--selection-controls .v-input__slot > .v-label {
  color: #0e180c;
}

.card-style {
  border-radius: 8px;
}

.checkbox-lost-harvest {
  background: #e6e9e6;
  border-radius: 8px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 8px !important;
}

.divider-point {
  margin: 10px;
  margin-bottom: 15px;
}

.distance-buttons {
  padding: 10px 20px;
}

.title-header {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  color: #1a2b46;
}

.form-register {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 500px;
  padding: 21px 42px;
  background: #f5f5f5 !important;
}

.button-remove-and-close {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  background: rgba(0, 0, 0, 0.4) !important;
}

.button-register-confirm {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  background-color: #39af49 !important;
  border-color: #39af49;
}

.icon-button {
  padding-top: 2px;
  padding-left: 8px;
}

.text-cancel-and-remove {
  color: #f8f9f8;
}

.inputs-border {
  border: 1px solid #e6e9e6;
  border-radius: 4px;
}

.btn-upload-file {
  box-shadow: 0px 2px 1px 0px rgba(11, 47, 16, 0.12) !important;
  background-color: white !important;
  color: #3f4e3c;
  font-size: 14px;
  font-family: Source Sans Pro;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0;
}

.info-file {
  color: gray;
  font-size: 10px;
  font-style: italic;
}
</style>
